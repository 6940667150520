import React, { useEffect, useState,useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "./ShowInvoice.css"; // Custom CSS file for styling
import config from "../../../functions/config";
import * as XLSX from 'xlsx';
import SalesInvoiceGraph from "./SalesInvoiceGraph"; // Import the graph component
import InvoiceImg from './image/InvoiceImg.png'
export default function ShowInvoice() {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [showFilterBox1, setShowFilterBox1] = useState(false);
  const [showFilterBox2, setShowFilterBox2] = useState(false);
  const [showFilterBox3, setShowFilterBox3] = useState(false);
  const [showFilterBox4, setShowFilterBox4] = useState(false);
  const [showFilterBox5, setShowFilterBox5] = useState(false);
  const [showFilterBox6, setShowFilterBox6] = useState(false);
  const [showFilterBox7, setShowFilterBox7] = useState(false);
  const filterIconRef = useRef(null);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [file, setFile] = useState(null);
  const [invoices, setInvoices] = useState([]); 
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [activeIndex, setActiveIndex] = useState(null); // To track the currently active row
  const [showOptions, setShowOptions] = useState(false); // To toggle the options box
  const navigate = useNavigate();
  // const [activeFilter, setActiveFilter] = useState(null); // Track active filter
  // const filterIconRef = useRef(null); // For positioning the filter box
  // const [filteredInvoices, setFilteredInvoices] = useState(invoices); // Full invoice list
  const [activeFilter, setActiveFilter] = useState(null);
  const [filters, setFilters] = useState({
    date: "",
    invoice: "",
    party: "",
    transaction: "",
    payment: "",
    amount: "",
    action: "",
    by: "",
  });
  
  const toggleFilterBox = (filterName) => {
  
    setActiveFilter((prev) => (prev === filterName ? null : filterName));
  };
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const handleRowClick = (invoice, index) => {
    console.log("Clicked Row Index:", index); // Logs row index
    console.log("Selected Invoice:", invoice); // Logs selected invoice data
    setSelectedInvoice(invoice); // Sets the selected invoice (State required)
  
    // Optional: Navigate to ViewSalesInvoice page
    navigate('/ViewSalesInvoice', { state: { invoice } });
  };
  
  
  const handleDownloadSample = () => {
    // Sample data for the 'invoice' sheet
    const invoiceData = [
      ["SLNO","InvoiceNo" ,"DATE", "NAME", "STATE OF SUPPLY", "DESCRIPTION", "SUB TOTAL", "IGST", "CGST", "SGST", "TAX AMOUNT", "ADJUSTMENT", "GRAND TOTAL"],
      [1,1, "2023-11-20", "Shahad", "otherState", "Sample Description", 1000, 50, 0, 0, 50, 0, 1050],
    ];

    // Sample data for the 'items' sheet (you can add items data similarly)
    const itemsData = [
      ["INVOICE NO", "NAME", "HSN", "QUANTITY", "PRICE", "TAX PERCENTAGE", "DISCOUNT", "TOTAL"],
      [1, "Item1", "1234", 2, 500, 10, 0, 1000],
    ];
    console.log("//",itemsData)
    // Create a workbook with both sheets
    const wb = XLSX.utils.book_new();
    const wsInvoice = XLSX.utils.aoa_to_sheet(invoiceData);
    const wsItems = XLSX.utils.aoa_to_sheet(itemsData);

    // Add sheets to workbook
    XLSX.utils.book_append_sheet(wb, wsInvoice, "invoice");
    XLSX.utils.book_append_sheet(wb, wsItems, "items");

    // Generate and download the Excel file
    XLSX.writeFile(wb, "SampleSalesInvoice.xlsx");
  };
  const handlePrint = () => {
    // Clone the table element
    const tableElement = document.getElementById("invoiceTable").cloneNode(true);
  
    // Remove the "Action" and "By" columns
    const tableRows = tableElement.rows; // Get all rows in the table
    
    for (let i = 0; i < tableRows.length; i++) {
      const cells = tableRows[i].cells; // Get cells of the row
      if (cells.length > 6) {
        cells[6]?.remove(); // Remove "Action" column (index 6)
        cells[6]?.remove(); // Remove "By" column (new index 6 after previous removal)
      }
    }
  
    // Remove the 3-dot ellipsis from the table
    const ellipsisElements = tableElement.querySelectorAll("span"); // Find all span elements
    ellipsisElements.forEach((element) => {
      if (element.innerHTML === "⋮" || element.innerHTML.includes("&#x22EE;")) {
        element.remove(); // Remove elements with ellipsis
      }
    });
  
    // Convert the modified table to HTML
    const tableContent = tableElement.outerHTML;
  
    // Create a new window for printing
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
       
          <style>
          h2{
          text-align: center;
          padding: 8px;
          }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
            }
            th {
              background-color: #3d4465;
              color: white;
              text-align: center; /* Center-align the text */
              font-weight: bold; /* Ensure the text is bold */
            }
          </style>
        </head>
        <body>
           <h2>SALES INVOICE TABLE</h2>
          ${tableContent}
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document for editing
    newWindow.print(); // Trigger the print dialog
    newWindow.close(); // Close the window after printing
  };
  
  // const handlePrint = () => {
  //   const tableContent = document.getElementById("invoiceTable").outerHTML; // Get table HTML
  //   const newWindow = window.open("", "_blank"); // Open a new window
  //   newWindow.document.write(`
  //     <html>
  //       <head>
  //         <title>Print Invoice Table</title>
  //         <style>
  //           table {
  //             width: 100%;
  //             border-collapse: collapse;
  //           }
  //           th, td {
  //             border: 1px solid #ddd;
  //             padding: 8px;
  //             text-align: left;
  //           }
  //           th {
  //             background-color: #3d4465;
  //             color: white;
  //           }
  //         </style>
  //       </head>
  //       <body>
  //         ${tableContent}
  //       </body>
  //     </html>
  //   `);
  //   newWindow.document.close(); // Close document for editing
  //   newWindow.print(); // Open the print dialog
  //   newWindow.close(); // Close the new window after printing
  // };
  // const handlePrint = () => {
  //   // Clone the table element
  //   const tableElement = document.getElementById("invoiceTable").cloneNode(true);
  
  //   // Remove the "Action" and "By" columns
  //   const tableRows = tableElement.rows; // Get all rows in the table
    
  //   for (let i = 0; i < tableRows.length; i++) {
  //     const cells = tableRows[i].cells; // Get cells of the row
  //     if (cells.length > 6) {
  //       cells[6]?.remove(); // Remove "Action" column (index 6)
  //       cells[6]?.remove(); // Remove "By" column (new index 6 after previous removal)
  //     }
  //   }
  
  //   // Remove the 3-dot ellipsis from the table
  //   const ellipsisElements = tableElement.querySelectorAll("span"); // Find all span elements
  //   ellipsisElements.forEach((element) => {
  //     if (element.innerHTML === "⋮" || element.innerHTML.includes("&#x22EE;")) { 
  //       element.remove(); // Remove elements with ellipsis
  //     }
  //   });
  
  //   // Convert the modified table to HTML
  //   const tableContent = tableElement.outerHTML;
  
  //   // Create a new window for printing
  //   const newWindow = window.open("", "_blank");
  //   newWindow.document.write(`
  //     <html>
  //       <head>
  //         <title >Sales Invoice Table</title>
  //         <style>
  //           table {
  //             width: 100%;
  //             border-collapse: collapse;
  //           }
  //           th, td {
  //             border: 1px solid #ddd;
  //             padding: 8px;
  //             text-align: left;
  //           }
  //           th {
  //             background-color: #3d4465;
  //             color: white;
  //           }
  //         </style>
  //       </head>
  //       <body>
  //         ${tableContent}
  //       </body>
  //     </html>
  //   `);
  //   newWindow.document.close(); // Close the document for editing
  //   newWindow.print(); // Trigger the print dialog
  //   newWindow.close(); // Close the window after printing
  // };
  
  
  
  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
    console.log("cleared")

  };
  
  const applyFilters = () => {
    const updatedFilteredInvoices = invoices.filter((invoice) => {
      return Object.keys(filters).every((key) => {
        if (filters[key]) {
          return invoice[key]?.toString().toLowerCase().includes(filters[key].toLowerCase());
        }
        return true;
      });
    });
    setFilteredInvoices(updatedFilteredInvoices);
  };
  useEffect(() => {
    const total = calculateTotalAmount();
    setTotalAmount(total);
  }, [filteredInvoices]);
  // useEffect(() => {
  //   const handleOutsideClick = (e) => {
  //     if (!e.target.closest(".filter-box")) {
  //       setActiveFilter(null); // Close the filter box if clicking outside
  //     }
  //   };
  
  //   document.addEventListener("click", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, []);
  
  
  //   document.addEventListener("click", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, []);
  const [data, setData] = useState([]);
  // const applyFilter = async () => {
  //   try {
  //     const user = Cookies.get("role");
  //     const user_id = Cookies.get("user_id");
  //     const response = await axios.get(`${config.base_url}api/filter_sales_invoices/`, {
  //       params: filters, 
  //       'user_id':user_id,"role":user  
       
  //     });
  //     setFilteredInvoices(response.data);
  //     console.log("filtered data",response.data)
  //     // setData(response.data.data); // Set filtered data
  //   } catch (error) {
  //     console.error('Error fetching filtered data:', error);
  //   }
  // };
  const [activeFilterBoxVisible, setActiveFilterBoxVisible] = useState(true); // State to track visibility of filter box

  const applyFilter = async () => {
    try {
        const user = Cookies.get("role"); // Get role from cookies
        const user_id = Cookies.get("user_id"); // Get user_id from cookies
        const salesInvoiceRegex = /^s(a|al|ale|ales|ales\s?i|ales\s?in|ales\s?inv|ales\s?invo|ales\s?invoi|ales\s?invoic|ales\s?invoice|ales\s?invoices)?$/i;

        // Ensure the transaction matches one of the valid prefixes
        if (filters.transaction && !salesInvoiceRegex.test(filters.transaction)) {
          // If 'Sales Invoice' is not found, show no data message
          setFilteredInvoices([]);
          // alert("No data found. Please ensure the transaction filter contains 'Sales Invoice'.");
          return;
      }
        const response = await axios.get(`${config.base_url}api/filter_sales_invoices/`, {
            params: {
                ...filters, // Existing filters
                user_id: user_id, // Add user_id to API call
                role: user, // Add role to API call
            },
        });

        setFilteredInvoices(response.data); 
        // Update state with filtered data
        console.log("Filtered data", response.data);
        setActiveFilter(null);
    } catch (error) {
        console.error("Error fetching filtered data:", error);
    }
};
const handleApplyClick = () => {
  applyFilter();
  // Keep the filter box visible after applying
  // setActiveFilterBoxVisible(true);
};
  // const clearFilter = (name) => {
  //   setFilters({ ...filters, [name]: '' });
    
  // };
  // const clearFilter = (name) => {
  //   console.log("Clearing filter for:", name);
  //   setFilters((prevFilters) => {
  //     return {
  //       ...prevFilters,
  //       [name]: ''
  //        // Clear the specific filter field
  //     };
      
  //   });
    
  //   setActiveFilter(null);
  //   try {
  //     const user = Cookies.get("role");
  //     const user_id = Cookies.get("user_id");
  //     // let url = `${config.base_url}api/sales_invoices`;
  //     // if (fromDate && toDate) {
  //     //   url += `?fromDate=${fromDate}&toDate=${toDate}`;
  //     // }
  //     const response = await axios.get(`${config.base_url}api/sales_invoices`, {
  //       params: {
  //           ...filters, // Existing filters
  //           user_id: user_id, // Add user_id to API call
  //           role: user, // Add role to API call
  //       },
  //   });
  //     // let url = `${config.base_url}api/sales_invoices`;
  //     // const response = await axios.get(url, {
  //     //   headers: {
  //     //     "Content-Type": "application/json",
  //     //   },
  //     //    params:{
  //     //     'user_id':user_id,"role":user
  //     //   }
  //     // });

  //     if (response.status === 200) {
  //       console.log("Fetched Invoices:", response.data);
  //       if (response.data && response.data.length > 0) {
  //         setInvoices(response.data);
  //         console.log(response.data);
      
  //         // Set the invoice count to 1 if there is data
  //         setInvoiceCount(1);
  //     } else {
  //         // Handle the case where there is no data
  //         setInvoiceCount(0); // Or any other logic for no data
  //     }
  //       setInvoices(response.data);
  //       console.log(response.data);
       
  //       // Apply search filtering after fetching data
  //       const filtered = response.data.filter((invoice) =>
  //         Object.values(invoice)
  //           .map((value) => (value ? value.toString() : ""))
  //           .join(" ")
  //           .toLowerCase()
  //           .includes(searchTerm)
  //       );
  //       setFilteredInvoices(filtered);
  //     } else {
  //       console.error("Unexpected response:", response);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching invoices:", error);
  //   }
  // };
  const clearFilter = async (name) => {
    try {
      console.log("Clearing filter for:", name);
      
      // Clear the specific filter field and update the state
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          [name]: '', // Reset the filter field to empty
        };
      });
  
      setActiveFilter(null); // Close the filter box after clearing
  
      // Get user role and ID from cookies
      const user = Cookies.get("role");
      const user_id = Cookies.get("user_id");
  
      // Fetch the invoices with the updated filters
      const response = await axios.get(`${config.base_url}api/sales_invoices`, {
        params: {
          ...filters, // Existing filters after clearing the specific one
          user_id: user_id, // Add user_id to API call
          role: user, // Add role to API call
        },
      });
  
      if (response.status === 200) {
        console.log("Fetched Invoices:", response.data);
  
        if (response.data && response.data.length > 0) {
          // If there are invoices, update the state
          setInvoices(response.data);
          setInvoiceCount(1); // Set the invoice count to 1 if data is available
        } else {
          // Handle case when no invoices are returned
          setInvoiceCount(0); // Or any other logic for no data
        }
  
        // Apply search filtering to the fetched data
        const filtered = response.data.filter((invoice) =>
          Object.values(invoice)
            .map((value) => (value ? value.toString() : ""))
            .join(" ")
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) // Ensure search term comparison is case-insensitive
        );
  
        // Set the filtered invoices in the state
        setFilteredInvoices(filtered);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };
  
  const renderFilterBox = (filterName, placeholder) => {
   
    if (activeFilter !== filterName) return null;

    return (
      <div
        className="filter-box"    
        style={{
          position: 'absolute',
          
          zIndex: 100,
          background: '#b4b4b450',
          padding: '10px',
          border: '1px solid #3498db',
          borderRadius: '5px',
          boxShadow:"3px 5px 5px 5px rgba(0, 0, 0, 0.201)",
         backdropFilter: 'blur(5px)', // Standard property
    WebkitBackdropFilter: 'blur(5px)',
    paddingTop:"50px",
   
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <label htmlFor={`${filterName}Filter`} className="pb-5"style={{ fontWeight: 'bold',paddingLeft:"80px" }}>
          Action<br/><br/><br/><br/>
        </label>
        {/* <input
          type="text" // Changed to 'text' for all filters, including date
          // id={`${filterName}Filter`}
          // className="form-control mb-3"
          // placeholder={`Filter ${placeholder}`}
          // value={filters[filterName]}
          id={`${filterName}Filter`}
          className="form-control mb-3"
          placeholder={`Filter ${placeholder}`}
          value={filters[filterName]} 
          // Controlled input with state value
          onChange={(e) => handleFilterChange(filterName, e.target.value)}
        /> */}
         <input
      type="text" // Changed to 'text' for all filters, including date
      id={`${filterName}Filter`}
      className="form-control mb-3"
      placeholder={`Filter ${placeholder}`}
      value={filters[filterName]} // Controlled input with state value
      onChange={(e) => handleFilterChange(filterName, e.target.value)} // Update state on input change
    />
        <div className="d-flex justify-content-end">
          {/* <button className="btn btn-secondary btn-sm bg-dark" onClick={() => clearFilter(filterName)}>
            Clear
          </button> */}
        
          <button className="btn btn-primary btn-sm bg-primary mr-2" 
          // onClick={handleApplyClick}
          onClick={(e) => {
            // e.stopPropagation(); // Prevent closing when clicking this button
            handleApplyClick();
          }}
          >
            Apply
          </button>
          
          <button className="btn btn-primary btn-sm bg-dark" onClick={() => clearFilter(filterName)}>
            Clear
          </button>
    
        </div>
      </div>
    );
  };


  
  
  
  // Table rendering remains the same
  
  const getFilterBoxStyle = () => ({
    position: "absolute",
    top: filterIconRef.current?.getBoundingClientRect().bottom + window.scrollY + 5, // Position below the filter icon
    left: filterIconRef.current?.getBoundingClientRect().left + window.scrollX, // Align with the left edge of the filter icon
    backgroundColor: "#ffffff",
    padding: "10px",
    border: "1px solid #ccc",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    width: "200px",
    zIndex: 1000,

  });
  const calculateTotalAmount = () => {
    return filteredInvoices.reduce((total, invoice) => {
      return total + parseFloat(invoice.grandtotal || 0); // Convert to float and handle empty values
    }, 0);
  };
  
  // Handle navigation to SalesInvoice with selected row's data
  const handleHistoryClick = (invoice) => {
    navigate("/salesInvoice", { state: { invoice } });
  };
  const handleOptionsClick = (index) => {
    // Toggle the visibility of the filter box
    setActiveIndex(index === activeIndex && showOptions ? null : index);
    setShowOptions(index !== activeIndex || !showOptions);
  };
  // Function to handle input change and filter data
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // const handleExcel = () => {
  //   const user = Cookies.get("role");
  //   const user_id = Cookies.get("user_id");
  //   fetch(`${config.base_url}export-sales-invoices/`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     },
  //     params:{
  //       'user_id':user_id,"role":user
  //     }
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.blob();
  //     })
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.style.display = "none";
  //       a.href = url;
  //       a.download = "SalesInvoices.xlsx"; // Specify the filename
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       document.body.removeChild(a); // Clean up the created element
  //     })
  //     .catch((error) => console.error("Error downloading the file:", error));
  // };
  const handleExcel = () => {
    const user = Cookies.get("role");
    const user_id = Cookies.get("user_id");
  
    // Construct the URL with query parameters
    const url = new URL(`${config.base_url}export-sales-invoices/`);
    url.searchParams.append("user_id", user_id);
    url.searchParams.append("role", user);
  
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const fileUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = fileUrl;
        a.download = "SalesInvoices.xlsx"; // Specify the filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(fileUrl);
        document.body.removeChild(a); // Clean up the created element
      })
      .catch((error) => console.error("Error downloading the file:", error));
  };
  
  const handleEdit = (invoiceId) => {
    window.location.href = `/edit-invoice/${invoiceId}`; // Navigate with invoice ID
  };
  
  // Filter invoices based on the search term
  const FInvoices = invoices.filter((invoice) =>
    Object.values(invoice)
      .map((value) => (value ? value.toString() : "")) // Handle null or undefined values
      .join(" ")
      .toLowerCase()
      .includes(searchTerm)
  );
  
  const handleDelete = (invoiceId) => {
    const confirmDelete = window.confirm("Do you really want to delete this invoice?");

    if (confirmDelete) {
    console.log("Deleting Invoice ID:", invoiceId);

    axios
        .delete(`${config.base_url}invoices/delete/${invoiceId}/`)
        .then((response) => {
            if (response.status === 200) {
                console.log("Invoice deleted successfully:", response.data.success);
                // Reload the page or update UI
                window.location.reload();
            } else {
                console.error("Error deleting invoice:", response.data.error);
            }
        })
        .catch((error) => {
            if (error.response) {
                // Server responded with a status other than 200
                console.error("Server error:", error.response.data.error);
            } else if (error.request) {
                // Request was made but no response received
                console.error("No response received:", error.request);
            } else {
                // Something else happened
                console.error("Error:", error.message);
            }
        });

       } else {
          // If the user cancels, do nothing
          console.log("Deletion canceled by the user.");
      }  
    };

  // const toggleFilterBox = () => {
  //   setShowFilterBox(!showFilterBox);
  // };
  const toggleFilterBox1 = () => {
    setShowFilterBox1(!showFilterBox1);
  };
  const toggleFilterBox2 = () => {
    setShowFilterBox2(!showFilterBox2);
  };
  const toggleFilterBox3 = () => {
    setShowFilterBox3(!showFilterBox3);
  };
  const toggleFilterBox4 = () => {
    setShowFilterBox4(!showFilterBox4);
  };
  const toggleFilterBox5 = () => {
    setShowFilterBox5(!showFilterBox5);
  };
  const toggleFilterBox6 = () => {
    setShowFilterBox6(!showFilterBox6);
  };
  const toggleFilterBox7 = () => {
    setShowFilterBox7(!showFilterBox7);
  };
  const [showModal, setShowModal] = useState(false);

  // Function to open the modal
  // const handleShowModal = () => setShowModal(true);
  const handleShowModal = () => {
    setShowModal(true);
  };
  // Function to close the modal
  // const handleCloseModal = () => setShowModal(false);
  const [totalAmount, setTotalAmount] = useState(0);
  

  // useEffect(() => {
  //   const fetchInvoices = async () => {
  //     try {
  //       const response = await axios.get('http://127.0.0.1:8000/api/sales_invoices', {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });
        
        
  
  //       if (response.status === 200) {
  //         console.log("Fetched Invoices:", response.data);
  //         setInvoices(response.data); // Update the `invoices` state
  //       } else {
  //         console.error("Unexpected response:", response);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching invoices:", error);
  //     }
  //   };
  
  //   fetchInvoices();
  // }, []);
  // const styleFilterBox = (() => {
  //   if (filteredInvoices.length > 4) {
  //     if (index === filteredInvoices.length - 1 || index === filteredInvoices.length - 2 || index === filteredInvoices.length - 3) {
  //       return {
  //         position: "absolute",
  //         top: -135,
  //         right: "0",
  //         backgroundColor: "#fff",
  //         border: "1px solid #ccc",
  //         boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
  //         zIndex: 100,
  //         width: "120px",
  //       };
  //     }
  //     return {
  //       position: "absolute",
  //       top: "70%",
  //       right: "0",
  //       backgroundColor: "#fff",
  //       border: "1px solid #ccc",
  //       boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
  //       zIndex: 100,
  //       width: "120px",
  //     };
  //   }
  
  //   switch (filteredInvoices.length) {
  //     case 1:
  //       return {
  //         position: "absolute",
  //         top: -35,
  //         right: "0",
  //         backgroundColor: "#fff",
  //         border: "1px solid #ccc",
  //         boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
  //         zIndex: 100,
  //         width: "120px",
  //       };
  //     case 2:
  //       return {
  //         position: "absolute",
  //         top: -15,
  //         right: "0",
  //         backgroundColor: "#fff",
  //         border: "1px solid #ccc",
  //         boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
  //         zIndex: 100,
  //         width: "120px",
  //       };
  //     case 3:
  //       return {
  //         position: "absolute",
  //         top: -50,
  //         right: "0",
  //         backgroundColor: "#fff",
  //         border: "1px solid #ccc",
  //         boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
  //         zIndex: 100,
  //         width: "120px",
  //       };
  //     default:
  //       return {};
  //   }
  // })();
  
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImport = async () => {
    if (!selectedFile) {
      alert("Please select a file!");
      return;
    }

    const formData = new FormData();
    formData.append("excel_file", selectedFile); // Ensure key matches the backend
    formData.append("role", Cookies.get("role"));
    formData.append("user_id", Cookies.get("user_id"));
    console.log("data",formData)
    console.log("FormData Contents:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    try {
      const response = await axios.post(
        `${config.base_url}import_invoice1/`, // API URL
        formData, // Send FormData
        {
          headers: {
            "Content-Type": "multipart/form-data", // Required header
          },
        }
      );

      // if (response.status === 200) {
      //   alert(response.data.success || "File imported successfully!");
      // } else {
      //   alert(response.data.error || "Failed to import file.");
      // }
      if (response.status === 200 && response.data.success) {
        alert(response.data.success); // Success message
        window.location.href = "/show_invoice/";
      
        // window.location.href = response.data.redirect_url; // Redirect to 'show_invoice'
      } else {
        alert(response.data.error || "Failed to import file.");
      }
    } catch (error) {
      window.location.href = "/show_invoice/";
      console.error("Error uploading file:", error);
      // window.location.href = "/show_invoice/";
      // alert("An error occurred while uploading the file.");
    }
};


  
  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert('Please select a file before submitting.');
      return;
    }

    const formData = new FormData();
    formData.append('excel_file', selectedFile);

    fetch('/import_invoice_form_excel', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        alert('File imported successfully!');
        // onClose();
      })
      .catch((error) => {
        console.error('Error importing file:', error);
        alert('Failed to import file.');
      });
  };
  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const user = Cookies.get("role");
        const user_id = Cookies.get("user_id");
        let url = `${config.base_url}api/sales_invoices`;
        if (fromDate && toDate) {
          url += `?fromDate=${fromDate}&toDate=${toDate}`;
        }

        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
          },
           params:{
            'user_id':user_id,"role":user
          }
        });

        if (response.status === 200) {
          console.log("Fetched Invoices:", response.data);
          if (response.data && response.data.length > 0) {
            setInvoices(response.data);
            console.log(response.data);
        
            // Set the invoice count to 1 if there is data
            setInvoiceCount(1);
        } else {
            // Handle the case where there is no data
            setInvoiceCount(0); // Or any other logic for no data
        }
          setInvoices(response.data);
          console.log(response.data);
         
          // Apply search filtering after fetching data
          const filtered = response.data.filter((invoice) =>
            Object.values(invoice)
              .map((value) => (value ? value.toString() : ""))
              .join(" ")
              .toLowerCase()
              .includes(searchTerm)
          );
          setFilteredInvoices(filtered);
        } else {
          console.error("Unexpected response:", response);
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };

    fetchInvoices();
  }, [fromDate, toDate, searchTerm]); // Dependencies include fromDate, toDate, and searchTerm
// Trigger the effect when fromDate or toDate changes
  
  return (
  
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex" style={{ background: "#636e99"}}>
        <Sidebar />
        <div className="main-panel w-100" style={{ background: "#636e99"}}>
        {invoiceCount === 1? (
          <div className="content-wrapper p-4" style={{ background: "#636e99"}}>
            {/* Header Section */}
            <div className="card bg-dark-blue p-3 mb-4">
              {/* <div className="d-flex justify-content-between align-items-center"> */}
                {/* <h4 className="text-white">Sales Invoice</h4> */}
                
              {/* </div> */}
              <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">

              <div className="d-flex flex-wrap justify-content-between align-items-center gap-5">
  <div className="d-flex flex-column flex-md-row align-items-center gap-3 m-2">
    <div className="form-check d-flex flex-column text-white mb-2">
      <label className="form-check-label mb-1 text-white pb-5 ml-3  ">From Date</label>
      <input
        type="date"
        value={fromDate}
        onChange={(e) => setFromDate(e.target.value)}
        className="form-check-date form-control-sm mt-2"
      />
    </div>

    <div className="form-check d-flex flex-column text-white mb-2 gap-3">
      <label className="form-check-label mb-1 text-white pb-5 ml-3">To Date</label>
      <input
        type="date"
        value={toDate}
        onChange={(e) => setToDate(e.target.value)}
        className="form-check-date form-control-sm mt-2"
      />
    </div>
  </div>
</div>




  <div className="d-flex flex-wrap align-items-center gap-2 mt-3 mt-md-0 p-2">
    <Link to="/SalesInvoiceGraph">
      <button className="btn btn-light btn-sm m-3">
        Graph <i className="fas fa-chart-bar" ></i>
      </button>
    </Link>
   
    {/* <button
        className="btn btn-light btn-sm m-3"
        // onClick={handleShowModal}
         data-bs-toggle="modal"
      data-bs-target="#Import"
      >
        Import <i className="fa-solid fa-file-import"></i>
      </button> */}
{/* <button
  className="btn btn-light btn-sm m-3"
  data-bs-toggle="modal"
  data-bs-target="#Import"
>
  Import <i className="fa-solid fa-file-import"></i>
</button> */}

      {/* Modal */}
      {/* {showModal && ( */}
      <button
  className="btn btn-light btn-sm m-3"
  data-bs-toggle="modal"
  data-bs-target="#Import"
>
  Import <i className="fa-solid fa-file-import"></i>
</button>
      <div
  className="modal fade"
  id="Import"
  tabIndex="-1"
  aria-labelledby="ImportLabel"
  aria-hidden="true"
>

          <div className="modal-dialog modal-xl" style={{ marginTop: '10%' }}>
            <div className="modal-content">
              <div className="modal-body rounded-1" style={{ backgroundColor: '#3d4465' }}>
                {/* Header */}
                <div className="d-flex justify-content-between">
                  <h3 className="m-3 text-white">IMPORT INVOICE</h3>
                  <button
                    type="button"
                    className="btn-close  text-white"
                      data-bs-dismiss="modal"
                              aria-label="Close"
                    // onClick={handleCloseModal}
                  >
                    {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                  </button>
                </div>

                {/* Instructions */}
                <div className="file_instructions">
                  <div className="alert bg-light text-dark" role="alert">
                    <span className="fw-bolder">IMPORTANT:</span>
                    <p>
                      File should have 2 sheets named 'invoice' & 'items' and column
                      names & order should be in the given format (Uppercase).
                    </p>
                    <p className="fw-bolder">
                      Invoice sheet - SLNO*, DATE, NAME*, STATE OF SUPPLY*, DESCRIPTION,
                      SUB TOTAL*, IGST*, CGST*, SGST*, TAX AMOUNT*, ADJUSTMENT, GRAND TOTAL*
                    </p>
                    <p className="fw-bolder">
                      Items sheet - INVOICE NO**, NAME*, HSN*, QUANTITY*, PRICE*, TAX
                      PERCENTAGE*, DISCOUNT, TOTAL*
                    </p>
                    <p>* All required columns should be filled.</p>
                    <p>** <b>INVOICE NO</b> is <b>SLNO</b> of invoice</p>
                    <p>
                      State of supply should be '<b>State</b>' or '<b>otherState</b>'.
                    </p>
                    <p>Date format should be 'YYYY-MM-DD' or 'DD-MM-YYYY'.</p>
                  </div>
                </div>

                {/* Download Sample File */}
                <div className="sample_file mb-2">
                  <a
                    // href="/download_invoice_sample_file" // Link to the Django view
                    className=" btn-primary fw-bolder btn btn-sm btn-primary bg-gradient"
                    onClick={handleDownloadSample} style={{background:"#2fdeb7"}}
                  >
                    Download Sample File
                  </a>
                </div>

                {/* File Upload Form */}
                {/* <form
                  onSubmit={handleImport}
                  className="form"
                  encType="multipart/form-data"
                > */}
                <div className="form-group">
  <label htmlFor="excel_file" className="form-label text-white">
    File
  </label>
  <input
    id="excel_file"
    className="form-control"
    type="file"
    name="excel_file"
    onChange={handleFileChange}
  />
</div>
<button onClick={handleImport}>Import</button>

                  {/* <div className="d-flex justify-content-center mt-2">
                    <input
                      id="submit_import_excel"
                      value="IMPORT"
                      className="btn btn-info bg-gradient"
                      type="submit"
                      style={{background:"#2fdeb7",color:"black"}}
                     
                    />
                  </div> */}
                {/* </form> */}
              </div>
            </div>
          </div>
        {/* </div> */}
</div>

      

    <button
      className="btn btn-light btn-sm m-3"
      onClick={() => handleExcel()}
    >
      Excel <i className="fa-solid fa-file-excel"></i>
    </button>

    <button className="btn btn-light btn-sm m-3" onClick={handlePrint}>
      Print <i className="fa-solid fa-print"></i>
    </button>
  </div>
</div>

            </div>

            {/* Table Section */}
            <div className="card bg-dark-blue p-3">
              <div className="d-flex  flex-wrap justify-content-between align-items-center mb-3">
                <h5 className="text-white">Sales Invoice</h5>
                 {/* <Link to='/item-list'><button style={{color:'#3d4465'}} className='btn fw-bold btn-light'><i className='fa-solid fa-beat fa-arrow-left'></i> Back</button></Link> */}
        
               
              </div>
              <div className="d-flex flex-wrap  justify-content-between">
              <input
                    type="text"
                    className="form-control  p-2 mb-4 w-25 " placeholder="Search" 
                    value={searchTerm}  onChange={handleSearchChange}
                  />
                    <Link to='/invoice'><button className="btn btn-light mr-4">+ Add SalesInvoice</button></Link>
                    </div>
                    <div >
            <div className="table-responsive bg-white  mt-3">
            <table className="table  table-light  " id="invoiceTable" style={{border:"none"}} >
                <thead style={{border:"none"}} >
                <tr style={{border:"none"}} >
          <th
            scope="col"
            style={{  backgroundColor: "#3d4465", color: "white", cursor: "pointer" }}
            // onClick={() => toggleFilterBox("date")}
            onClick={() => setActiveFilter('date')}
          >
            DATE <i className="fa-solid fa-filter"></i>  {renderFilterBox('date', 'Select Date')}
          </th>
         

          <th
            scope="col"
            style={{ backgroundColor: "#3d4465", color: "white", cursor: "pointer" }}
            onClick={() => setActiveFilter('invoice_no')}
          >
            INVOICE NO <i className="fa-solid fa-filter"></i>{renderFilterBox('invoice_no', 'Invoice No')}
          </th>
          

          <th
            scope="col"
            style={{ backgroundColor: "#3d4465", color: "white", cursor: "pointer" }}
            onClick={() => setActiveFilter('party_name')}
          >
            PARTY NAME <i className="fa-solid fa-filter"></i>  {renderFilterBox('party_name', 'Party Name')}
          </th>
         

          <th
            scope="col"
            style={{ backgroundColor: "#3d4465", color: "white", cursor: "pointer" }}
            onClick={() => toggleFilterBox("transaction")}
          >
            TRANSACTION <i className="fa-solid fa-filter"></i> {renderFilterBox("transaction",'Transaction')}{renderFilterBox('paymenttype', 'Payment Type')}
          </th>
         

          <th
            scope="col"
            style={{ backgroundColor: "#3d4465", color: "white", cursor: "pointer" }}
            onClick={() => setActiveFilter('paymenttype')}
          >
            PAYMENT <i className="fa-solid fa-filter"></i> {renderFilterBox('grandtotal', 'Amount')}
          </th>
          
          
          <th
            scope="col"
            style={{ backgroundColor: "#3d4465", color: "white", cursor: "pointer" }}
            onClick={() => setActiveFilter('grandtotal')}
          >
            AMOUNT <i className="fa-solid fa-filter"></i>{renderFilterBox('action', 'Action')} {renderFilterBox('by','By')}
          </th>
       
          <th
            scope="col"
            style={{ backgroundColor: "#3d4465", color: "white", cursor: "pointer" }}
            onClick={() => setActiveFilter('action')}
          >
        ACTION <i className="fa-solid fa-filter"></i> 
        {/* {renderFilterBox('action', 'Action')} */}
       </th>
        
        
          <th
            scope="col"
            style={{ backgroundColor: "#3d4465", color: "white", cursor: "pointer" }}
            // onClick={() => toggleFilterBox("by")}
            onClick={() => setActiveFilter('by')}
          >
            BY <i className="fa-solid fa-filter"></i>  
          </th>
         
        </tr>
        
                </thead>

               
                <tbody>
                  {filteredInvoices.length > 0 ? (
                    filteredInvoices.map((invoice, index) => (
                      <tr key={index} >
                        <td  onClick={() => handleRowClick(invoice, index)}>{invoice.date || "N/A"}</td>
                        <td  onClick={() => handleRowClick(invoice, index)}>{invoice.invoice_no || "N/A"}</td>
                        <td  onClick={() => handleRowClick(invoice, index)}> {(invoice.party_name && invoice.party_name.toLowerCase() !== "null") ? invoice.party_name : "N/A"}
                        </td>
                        <td  onClick={() => handleRowClick(invoice, index)}>{'Sales Invoice'}</td>
                        <td  onClick={() => handleRowClick(invoice, index)}>{invoice.paymenttype || "N/A"}</td>
                        <td  onClick={() => handleRowClick(invoice, index)}>{invoice.grandtotal || "0.0"}</td>
                        <td  onClick={() => handleRowClick(invoice, index)}>{invoice.action || "N/A"}</td>
                        <td>
  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between",cursor: "pointer", }}>
    <span  onClick={() => handleRowClick(invoice, index)}>
      {/* {invoice.company} */}
      {/* {invoice.staff_name ? invoice.staff_name : invoice.company} */}
      {invoice.staff_name !== null && invoice.staff_name !== undefined 
  ? invoice.staff_name 
  : invoice.company_name}</span>
    <div style={{ position: "relative",cursor: "pointer", }}>
      <span
        onClick={() => handleOptionsClick(index)}
        style={{
          cursor: "pointer",
          display: "inline-block",
          padding: "5px",
        }}
      >
        &#x22EE; {/* Unicode for vertical ellipsis */}
      </span>
      {showOptions && activeIndex === index && (
        
        <div  style={(() => {
          if (filteredInvoices.length > 3) {
            if (
              index === filteredInvoices.length - 1 ||
              index === filteredInvoices.length - 2 
              // ||
              // index === filteredInvoices.length - 3
            ) {
              return {
                position: "absolute",
                top: -135,
                right: "0",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                zIndex: 100,
                width: "120px",
              };
            }
            return {
              position: "absolute",
              top: "70%",
              right: "0",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
              zIndex: 100,
              width: "120px",
            };
          }
    
          switch (filteredInvoices.length) {
            // case 1:
              
            //   return {
            //   position: "absolute",
            //   top: "70%",
            //   right: "0",
            //   backgroundColor: "#fff",
            //   border: "1px solid #ccc",
            //   boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            //   zIndex: 100,
            //   width: "120px",
            //   };
            
            // case 2:
            //   return {
            //     position: "absolute",
            //     top: -200,
            //     right: "0",
            //     backgroundColor: "#fff",
            //     border: "1px solid #ccc",
            //     boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            //     zIndex: 100,
            //     width: "120px",
            //   };
            case 1:
              return {
                position: "absolute",
                top: -10,
                marginRight:"15px",
                right: "0",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                zIndex: 100,
                width: "120px",
              };
            default:
              return { position: "absolute",
                top: -30,
                marginRight:"15px",
                right: "0",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                zIndex: 100,
                width: "120px",};
          }
        })()}
          // style=
          // {index===(filteredInvoices.length-1)?{position: "absolute",
          //   top: -135,
          //   right: "0",
          //   backgroundColor: "#fff",
          //   border: "1px solid #ccc",
          //   boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          //   zIndex: 100,
          //   width: "120px",}:
          //   index=== filteredInvoices.length-2?
          //   {position: "absolute",
          //     top: -135,
          //     right: "0",
          //     backgroundColor: "#fff",
          //     border: "1px solid #ccc",
          //     boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          //     zIndex: 100,
          //     width: "120px",}: 
          //     index=== filteredInvoices.length-3?
          //     {position: "absolute",
          //       top: -135,
          //       right: "0",
          //       backgroundColor: "#fff",
          //       border: "1px solid #ccc",
          //       boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          //       zIndex: 100,
          //       width: "120px",}:{
          //   position: "absolute",
          //   top: "70%",
          //   right: "0",
          //   backgroundColor: "#fff",
          //   border: "1px solid #ccc",
          //   boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          //   zIndex: 100,
          //   width: "120px",
          // }}
        >
          <div
            style={{
              // padding: "2px",
              // cursor: "pointer",
              borderBottom: "1px solid #eee",
            }}
          >
              <Link to="/ViewSalesInvoice"  state={{ invoice }}>
  <button style={{ color: '#3d4465',fontSize:"13px" ,outline: 'none',width:"100%",
    boxShadow: 'none',textAlign:"Left"}} className="btn fw-bold btn-light"   >
    <i className="fas fa-eye" style={{ color: 'green' }}></i> View
  </button>
</Link>

        
            {/* <i className="fas fa-eye" style={{color:"green"}}></i> View */}
          </div>
          {/* <div
            style={{
              padding: "8px",
              cursor: "pointer",
              borderBottom: "1px solid #eee",
            }}
          >
           <i class="fas fa-edit" style={{color:"blue"}}></i> Edit

          </div> */}
           <div
            style={{
              // padding: "2px",
              // cursor: "pointer",
              borderBottom: "1px solid #eee",
              
            }}
          >
       <Link
  to="/InvoiceEdit"
  state={{ invoice }}
  style={{ textDecoration: 'none' }}
>
  <button
    style={{ color: '#3d4465', fontSize:"13px",width:"100%",textAlign:"Left"}}
    className="btn fw-bold btn-light" 
  >
    <i class="fas fa-edit" style={{color:"blue"}}></i> Edit
  </button>
</Link>
</div>
<div
            style={{
              // padding: "2px",
              cursor: "pointer",
              borderBottom: "1px solid #eee",
            }}
          >
          {/* <div
            style={{
              padding: "8px",
              cursor: "pointer",fontSize:"13px",width:"100%",textAlign:"Left"
             
            }}
            onClick={() => handleDelete(invoice.id, index)}
            // onClick={() => handleDelete(index)}
          > */}
           <button
    style={{ color: '#3d4465',fontSize:"13px",width:"100%",textAlign:"Left" }}
    className="btn  btn-light" 
    onClick={() => handleDelete(invoice.id, index)}
  >
           <i class="fas fa-trash-alt" style={{color:'red'}}></i> <b style={{color:"#3d4465"}}>Delete</b>
           </button>
          {/* </div> */}
          </div>
          <div
            style={{
              // padding: "2px",
              cursor: "pointer",
            }}
          >
              {/* <Link to="/salesInvoice">
  <button style={{ color: '#3d4465' }} className="btn fw-bold btn-light"  onClick={() => handleHistoryClick(invoice)} >
    <i className="fas fa-history" style={{ color: 'green' }}></i>    History
  </button>
</Link> */}
<Link
  to="/salesInvoice"
  state={{ invoice }}
  style={{ textDecoration: 'none' }}
>
  <button
    style={{ color: '#3d4465',fontSize:"13px",width:"100%",textAlign:"Left" }}
    className="btn fw-bold btn-light" 
  >
    <i className="fas fa-history" style={{ color: 'green' }}></i> History
  </button>
</Link>

            {/* <i class="fas fa-history"style={{color:'blue'}}></i>
            History */}
          </div>
        </div>
      )}
    </div>
  </div>
</td>

                      </tr>
                    )
                   ) 
                )  
                 : (
                  <tr>
                      <td colSpan="8">No data available</td>
                   </tr>
                   )}
                </tbody>
              </table>
              <div className="bg-white mt-3 pl-3 pb-3 pt-5" style={{color:"#3d4465",fontSize:"25px"}}>Total Amount: {totalAmount.toFixed(2)} </div>
              </div>
             </div>
            </div>
          
          </div>):(
          
  // <div className="main-panel w-100">
<div className="img" style={{ background: "#636e99", position: "relative", height: "90vh", overflow: "hidden" }}>
  <img
    src={InvoiceImg}
    alt=""
    style={{
      width: "100%",
      height: "100%",
      objectFit: "cover", // Ensures the image covers the entire area without stretching
      position: "absolute", // Ensures the image is fixed inside the container
      top: "0",
      left: "0",
      paddingLeft:"20px",
      paddingTop:"20px",paddingBottom:"20px"
    }}
  />
  <Link
    style={{
      backgroundColor: "#3d4465",
      color: "white",
      position: "absolute", // Absolute positioning within the parent container
      bottom: "20px", // Adjust the position as needed (distance from the bottom)
      left: "50%", // Center horizontally
      transform: "translateX(-50%)", // Ensures the button is exactly centered
      padding: "10px 20px", // Adjust padding as needed
      fontSize: "16px", // Default font size
      textAlign: "center", 
      marginBottom:"10px"// Ensures the text is centered inside the button
    }}
    className="btn"
    to={"/Invoice"}
  >
    Create Your First Invoice
  </Link>
</div>


                // </div>
              
              )}
       
        </div>
      </div>
    </div>
  );
}
